.App {
  text-align: center;
  font-family: Helvetica, Arial, sans-serif
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dropdown-menu.show {
    background-color:transparent;
    border: none;
    border-radius: 0;
    text-align: center;

}

.dropdown-item {
  color: rgba(0,0,0,.5);
 
}

.navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0,0,0,.5); 
  }

.navbar-light .navbar-nav .show>.nav-link:hover {
    color: rgba(0,0,0,.9); 
    }
  
.unmarked{
      background-color: transparent !important;
      color: black !important;
  }

.form-control:disabled, .form-control[readonly] {
    background-color:white !important; 
    opacity: 1;
}

.navbar-brand{
    position: absolute;
    left:0;
    top:0;
    margin-left: 3px;
    width:25%;
    max-width: 150px;
    
}

.navbar {
  min-height:48px;
  background-color:rgba(179, 206, 245, 0.062);
}

.navbar-brand>img {
  width: 100%;
  max-width: 130px;
  height: auto; 
}

.navbar-toggler {
    display:inline-block;
    position: absolute;
    margin-top: 3px;
    margin-right: 5px;
    right:0;
    top:0;
}
.navbar-text {
  position: absolute;
  margin-top:4px;
  left:0;
  right:0;
  top: 0;
}

a {
  z-index: 1;
}

.card-header{
  border-color: rgba(55, 0, 255, 0.15);
  color: black;
  font-size: 1.5em;
  background-color:rgba(179, 206, 245, 0.2);
  
}

.list-group-item {
  border-color: rgba(55, 0, 255, 0.2);
  background-color:rgba(179, 206, 245, 0.062);
}

.card {
  margin-right: 5%;
  margin-left: 5% ;
  margin-top: 1em;
  margin-bottom: 1em ;
  border-color: rgba(55, 0, 255, 0.2);
  border-width: 1px;
  border-radius: 10px;
  overflow: hidden;
  background-color:rgba(179, 206, 245, 0.062);
}

.form-control{
  width:90%;
  margin:auto;
  background-color:white
}

.wrapper {
  background-color: rgba(0, 0, 255, 0.041);
  margin-right: 5%;
  margin-left: 5% ;
  margin-top: 1em;
  margin-bottom: 1em ;
  border-width: 2px !important;
  border:solid;
  border-color: rgba(55, 0, 255, 0.1);
  border-radius: 10px;
}

.answerbutton{
  background-color: rgba(0, 0, 255, 0.05) !important;
  min-width:75%;
  max-width:75%;
  border-radius: 15px;
}





